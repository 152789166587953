<template>
    <Transition>
        <button v-show="show" class="go-up" @click="goUp">
            <img alt="Go Up" src="~/assets/icons/go_up.svg" loading="lazy" >
        </button>
    </Transition>
</template>

<script setup>
const show = ref(false);

onMounted(() => {
    show.value = window.scrollY > 300;
    document.addEventListener("scroll", () => {
        show.value = window.scrollY > 300;
    });
});

function goUp() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

</script>

<style lang="scss" scoped>
.go-up {
    position: fixed;
    right: 1em;
    bottom: 1em;

    width: 50px;
    height: 50px;

    background: rgba(0, 0, 0, 0.7);
    border: 0;

    cursor: pointer;

    img {
        width: 50%;
        height: 50%;
    }
}
</style>
